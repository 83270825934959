import axios from "axios"
import useCatchHandler from "@/services/catchErrorHandler"
import useOAuth from "@/services/oauth"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"
import { useStore } from "@/store"
import { computed } from "vue"

export default function useApiDiscover() {
    const { handleError } = useCatchHandler()
    const { API_PREFIX } = useOAuth()

    const calculatePercentage = (done: number, total: number) => {
        if (total === 0) {
            return 0 // Avoid division by zero
        }
        const percentage = (done / total) * 100
        return Math.round(percentage)
    }

    const store = useStore()
    const getApiRequestHeader = computed(
        () => store.getters.getApiRequestHeader
    )

    const apGetDiscoverData = async () => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/discover`,
                getApiRequestHeader.value
            )
            store.dispatch(
                GlobalActions.SET_DISCOVER_PERCENTAGE,
                calculatePercentage(
                    response?.data?.doneCount ?? 0,
                    response?.data?.totalCount ?? 0
                )
            )
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUpdateDiscover = async (discover) => {
        try {
            const body = {
                discoverId: discover.discoverId ?? discover.id,
                showOnDiscover: true,
                isTodo: discover.isTodo,
            }
            return await axios.post(
                `${API_PREFIX}/discover`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    return {
        apGetDiscoverData,
        apiUpdateDiscover,
    }
}
